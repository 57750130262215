
import TeamPhoto from './TeamPhoto';

const delay1 = 100;
const delay2 = window.innerWidth >= 768 ? 200 : 100;
const delay3 = window.innerWidth >= 768 ? 300 : 100;

const TeamMembers: React.FC = () => {
    return (
        <div className='flex flex-col gap-[6rem] my-[8rem] px-[10%]'>
            <div className="flex md:flex-row md:flex-wrap lg:flex-nowrap lg:justify-between justify-center items-center gap-[4rem] lg:gap-0 flex-col">
            <TeamPhoto photo="Bidur Giri" delay={delay1} name="Bidur Giri" position="CEO & Founder" />
<TeamPhoto photo="Bharat Giri" delay={delay2} name="Bharat Giri" position="Researcher" />
{/* <TeamPhoto photo="Anish Mainali" delay={delay3} name="Anish Mainali" position="Technical Manager" /> */}
            </div>
            <div className="flex md:flex-row md:justify-around flex-col items-center justify-center gap-[4rem] md:gap-0">
            <TeamPhoto photo="Daman Ghale" delay={delay1} name="Daman Ghale" position="Marketing Head" />
            <TeamPhoto photo="Susila Malla" delay={delay2} name="Susila Malla" position="Accountant" />
            </div>
            <div className="flex md:flex-row md:flex-wrap lg:flex-nowrap lg:justify-between justify-center items-center gap-[4rem] lg:gap-0 flex-col">
            <TeamPhoto photo="Lalan Tharu" delay={delay1} name="Lalan Tharu" position="Graphics Designer" />
<TeamPhoto photo="Junaid Sir" delay={delay2} name="Junaid Sir" position="Social Media Manager" />
<TeamPhoto photo="Sulav Bhandari" delay={delay3} name="Sulav Bhandari" position="Content Creator" />
            </div>
        </div>
    );
}

export default TeamMembers;

